'use client';

import { Inter as FontSans } from 'next/font/google';
import { cn } from '@/lib/utils';
import '../../styles/globals.css';
import { SessionProvider } from 'next-auth/react';
const fontSans = FontSans({
  subsets: ['latin'],
  variable: '--font-sans'
});
export default function RootLayout({
  children
}: {
  children: React.ReactNode;
}) {
  return <html lang='en' data-sentry-component="RootLayout" data-sentry-source-file="layout.tsx">
            <body className={cn('min-h-screen bg-background font-sans antialiased', fontSans.variable)}>
                <main className='w-full h-screen flex items-center justify-center'>
                    <SessionProvider data-sentry-element="SessionProvider" data-sentry-source-file="layout.tsx">{children}</SessionProvider>
                </main>
            </body>
        </html>;
}